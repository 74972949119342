<template>
    <div class="page">
        <div class="page_con">
            <div class="page_row">
                <span>字体显示</span>
                <a-switch class="right" style="margin-top:12.5px" checked-children="开" un-checked-children="关" default-checked/>
            </div>
        </div>
        
        <div class="page_con">
            <div class="page_row"><span>使用手册</span><img class="right" src="@/assets/imgs/wap/right.png" alt=""></div>
            <div class="page_row"><span>意见反馈</span><img class="right" src="@/assets/imgs/wap/right.png" alt=""></div>
            <div class="page_row"><span>关于我们</span><img class="right" src="@/assets/imgs/wap/right.png" alt=""></div>
        </div>
    </div>
</template>
<script>
// import $ from "jquery";
// import {requestXml} from '../../assets/js/request';
// const wx = window.wx;
export default {
    data() {
        return {
            
        };
    },
    mounted: function(){
        
    },
    methods:{
    }
};
</script>
<style scoped>
    .page{
        overflow: hidden;
    }
    .page_con{
        margin: 20px auto;
    }
    .page_row{
        width: 100%;
        background: #fff;
        height: 50px;
        line-height: 50px;
        border: 1px solid #EFEFEF;
        padding: 0 20px;
    }
    .page_row .right{
        width: 9px;
        height: auto;
        float: right;
        margin-top: 17px;
    }
    
</style>
